import { Locales } from '@/enums/general'
import { Locale, format } from 'date-fns'
import * as dateFnsLocales from 'date-fns/locale'

// Function to convert milliseconds timestamp to digital time format (e.g., "10:00" or "15:00")
export function formatMillisecondsToDigitalTime(milliseconds: number): string {
  if (isNaN(milliseconds)) {
    return ''
  }

  const date = new Date(milliseconds)
  return format(date, 'HH:mm')
}

export type SupportedLocales = (typeof Locales)[keyof typeof Locales]

export function getDateFNSLocale(localeKey: SupportedLocales): Locale {
  if (localeKey === Locales.en || localeKey === Locales.no) {
    return dateFnsLocales.enUS
  }

  return dateFnsLocales[localeKey]
}
