import { Locales } from '@/enums/general'

export const ChatTypes = {
  'support-chat': 'support-chat',
  'agent-chat': 'agent-chat',
  'notes-chat': 'notes-chat'
} as const

export const ChatTypesArray = Object.keys(ChatTypes) as Array<keyof typeof ChatTypes>

export const SenderReceiverTypes = {
  bot: 'bot',
  agent: 'agent',
  customer: 'customer'
} as const

export const AllSenderReceiverTypes = Object.keys(SenderReceiverTypes) as Array<keyof typeof SenderReceiverTypes>


type AllLocalesObjectString = {
  [Key in keyof typeof Locales]: string
}

export const WelcomeMessages: AllLocalesObjectString = {
  nl: 'Hi, we stellen je een paar vragen om je beter te kunnen helpen. Als we er niet uitkomen helpt een medewerker je verder.',
  en: "We're asking a few questions to enhance our ability to assist you more effectively. If we can't find a solution, an employee will help you further.",
  de: 'Hallo, wir stellen dir einige Fragen, um dir besser helfen zu können. Wenn wir keine Lösung finden, wird dir ein Mitarbeiter weiterhelfen.',
  fr: "Salut, nous te posons quelques questions pour mieux t'assister. Si nous ne trouvons pas de solution, un employé te viendra en aide.",
  es: 'Hola, te hacemos algunas preguntas para poder ayudarte mejor. Si no llegamos a una solución, un empleado te asistirá.',
  sv: 'Hej, vi ställer några frågor för att kunna hjälpa dig bättre. Om vi inte kan lösa det, hjälper en medarbetare dig vidare.',
  da: 'Hej, vi stiller dig nogle spørgsmål for at kunne hjælpe dig bedre. Hvis vi ikke kan finde en løsning, vil en medarbejder hjælpe dig videre.',
  it: 'Ciao, ti facciamo alcune domande per poterti aiutare meglio. Se non riusciamo a risolvere, un dipendente ti assisterà ulteriormente.',
  pl: 'Cześć, zadajemy ci kilka pytań, aby lepiej ci pomóc. Jeśli nie znajdziemy rozwiązania, pracownik pomoże ci dalej.',
  pt: 'Olá, fazemos algumas perguntas para poder ajudar-te melhor. Se não conseguirmos encontrar uma solução, um colaborador te ajudará mais.',
  fi: 'Hei, esitämme sinulle muutaman kysymyksen voidaksemme auttaa sinua paremmin. Jos emme pääse yhteisymmärrykseen, työntekijä auttaa sinua eteenpäin.',
  no: 'Hei, vi stiller deg noen spørsmål for å kunne hjelpe deg bedre. Hvis vi ikke finner en løsning, vil en medarbeider hjelpe deg videre.',
  cs: 'Ahoj, ptáme se tě na několik otázek, abychom ti mohli lépe pomoci. Pokud nenajdeme řešení, zaměstnanec ti bude dál asistovat.'
} as const
