import { LocalesArray, Locales } from '@/enums/general'
import { storageKeys } from '@/enums/localStorage'
import { differenceInMonths as differenceInMonthsFunction } from 'date-fns'

export const isSupportedLocale = (locale: string): boolean => {
  return LocalesArray.includes(locale as keyof typeof Locales)
}

export const reverseArray = <T>(arr: T[]): T[] => arr.slice().reverse()

export const sleep = (seconds: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000) // Convert seconds to milliseconds
  })
}

export const isAtleastMonthsAfterGivenDate = (givenDate: Date, monthsDifference: number = 1) => {
  const now = new Date()

  const differenceInMonths = differenceInMonthsFunction(now, givenDate)

  if (differenceInMonths >= monthsDifference) {
    return true
  }

  return false
}

export const asyncForEach = async <T>(
  array: T[],
  callback: (item: T, index: number, array: T[]) => Promise<void>
): Promise<void> => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

const clearLocalStorageItem = (key: string): void => {
  try {
    localStorage.setItem(key, JSON.stringify(null))
  } catch (error) {
    console.error(`Failed to clear localStorage for key "${key}": ${error}`)
  }
}

// This is not reactive, use ts-hooks if possible
export const clearAllLocalStorageItems = () =>
  (Object.keys(storageKeys) as Array<keyof typeof storageKeys>).forEach((key) => clearLocalStorageItem(key))

export const serializeParameters = (obj: Record<string, string | number> = {}): string => {
  const str: string[] = []
  const keys: string[] = Object.keys(obj)

  keys.forEach((key: string) => {
    if (obj[key]) {
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    }
  })

  return str.join('&')
}
