import { Order, OrderBundle } from '@/core/services/types/getOrderBundles/responseTypes'
import { Runtime } from 'sst/constructs'

export const ErrorMessages = {
  NotAuthenticated: 'Not Authenticated',
  BadRequest: 'Bad Request'
} as const

export const Locales = {
  nl: 'nl',
  en: 'en',
  de: 'de',
  fr: 'fr',
  es: 'es',
  sv: 'sv',
  da: 'da',
  it: 'it',
  pl: 'pl',
  pt: 'pt',
  fi: 'fi',
  no: 'no',
  cs: 'cs'
} as const

export const LocalesArray = Object.keys(Locales) as Array<keyof typeof Locales>

export const DefaultLocale: keyof typeof Locales = Locales.nl

export type ImageType = {
  desktop: string
  mobile: string
  fallback: string
}

export type NewOrder = Omit<Order, 'product'> & {
  product: Omit<Order['product'], 'productImages'> & { productImages: ImageType }
}

export type NewOrderBundle = Omit<OrderBundle, 'orders'> & { orders: NewOrder[] }

export const runtime: Runtime = 'nodejs18.x'
